<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useCmsTranslations } from "@shopware/composables";
import { ApiClientError, type ApiError } from "@shopware/api-client";
import { useNewsletter } from "#imports";
import {computed, onMounted, reactive, ref} from "vue";
import { defu } from "defu";
const { pushSuccess, pushError } = useNotifications();
import type { CmsElementZebraCmsFaq } from "~/composables/useCustom";

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const {t} = useI18n()


const props = defineProps<{
  content: CmsElementZebraCmsFaq;
  style: any,
  class: any
}>();

const { columns } = props.content?.config

type Translations = {
  form: {
    subscribeLabel: string;
    unsubscribeLabel: string;
    action: string;
    email: string;
    emailPlaceholder: string;
    salutation: string;
    salutationPlaceholder: string;
    firstName: string;
    firstNamePlaceholder: string;
    lastName: string;
    lastNamePlaceholder: string;
    privacy: string;
    privacyLabel: string;
    submit: string;
    newsletterBenefits: string;
  };
};

let translations: Translations = {
  form: {
    subscribeLabel: "Subscribe to newsletter",
    unsubscribeLabel: "Unsubscribe from newsletter",
    action: "Action",
    email: "Email address",
    emailPlaceholder: "Enter email address...",
    salutation: "Salutation",
    salutationPlaceholder: "Enter salutation...",
    firstName: "First name",
    firstNamePlaceholder: "Enter first name...",
    lastName: "Last name",
    lastNamePlaceholder: "Enter last name...",
    privacy: "Privacy",
    privacyLabel: "I have read the data protection information.",
    submit: "Submit",
    newsletterBenefits:
        "Be aware of upcoming sales and events.Receive gifts and special offers!",
  },
};

const {
  isLoggedIn,
  user,
} = useUser();

translations = defu(useCmsTranslations(), translations) as Translations;

const loading = ref<boolean>();
const formSent = ref<boolean>(false);
const errorMessages = ref<ApiError[]>([]);
const subscriptionOptions: {
  label: string;
  value: "subscribe" | "unsubscribe";
}[] = [
  {
    label: translations.form.subscribeLabel,
    value: "subscribe",
  },
  {
    label: translations.form.unsubscribeLabel,
    value: "unsubscribe",
  },
];

const { newsletterSubscribe, newsletterUnsubscribe } = useNewsletter();

const state = reactive({
  option: subscriptionOptions[0].value,
  salutationId: "",
  firstName: "Guest",
  lastName: "User",
  email: "",
  checkbox: true,
});

const rules = computed(() => {
  let temp: any = {
    email: {
      required,
      email,
    },
    checkbox: {
      required,
      isTrue: (value: any) => value === true,
    },
  };
  if (state.option === "subscribe") {
    temp = {
      ...temp,
      firstName: {
        required: false,
        minLength: 3,
      },
      lastName: {
        required: false,
        minLength: 3,
      },
      salutationId: {
        required: false,
      },
    };
  }
  return temp;
});

const $v = useVuelidate(rules, state);
const invokeSubmit = async () => {
  $v.value.$touch();
  const valid = await $v.value.$validate();
  if (valid) {
    loading.value = true;
    try {
      if (state.option === "subscribe") {
        await newsletterSubscribe({
          ...state,
        });
        pushSuccess(t('signup.newsletter.success'))
      } else {
        await newsletterUnsubscribe(state.email);
        pushSuccess(t('unsubscribe.newsletter.success'))
      }
      formSent.value = true;
    } catch (e) {
      if (e instanceof ApiClientError) {
        errorMessages.value = e.details.errors;
        pushError(t('signup.newsletter.error'))
      }
    } finally {
      loading.value = false;
    }
  }
};

onMounted(()=> {
  if(isLoggedIn.value) {
    state.firstName = user.value?.firstName || "";
    state.lastName = user.value?.lastName || "";
    state.email = user.value?.email || "";
    state.salutationId = user.value?.salutationId || "";
  } else {
    state.firstName = "Guest";
    state.lastName = "User";
    state.salutationId = "0190930c2710707abc5e471318d9bd07";
  }
})

</script>

<template>
  <div class="py-28 lg:pt-32 sm:pb-16 md:pb-24 lg:pb-36 top-btm-mob">
    <div class="container">
      <div class="grid md:grid-cols-3 lg:grid-cols-3 gap-y-24 lg:gap-y-16 md:gap-x-[40px] mob320_gap">
        <div class="w-[280px] sm:w-auto">
          <div class="flex items-center mb-[10px]">
              <span class="f_icons w-10 h-10 flex items-center">
                  <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 20.5C17.1 20.5 18.0417 20.1083 18.825 19.325C19.6083 18.5417 20 17.6 20 16.5C20 15.4 19.6083 14.4583 18.825 13.675C18.0417 12.8917 17.1 12.5 16 12.5C14.9 12.5 13.9583 12.8917 13.175 13.675C12.3917 14.4583 12 15.4 12 16.5C12 17.6 12.3917 18.5417 13.175 19.325C13.9583 20.1083 14.9 20.5 16 20.5ZM16 35.2C20.0667 31.4667 23.0833 28.075 25.05 25.025C27.0167 21.975 28 19.2667 28 16.9C28 13.2667 26.8417 10.2917 24.525 7.975C22.2083 5.65833 19.3667 4.5 16 4.5C12.6333 4.5 9.79167 5.65833 7.475 7.975C5.15833 10.2917 4 13.2667 4 16.9C4 19.2667 4.98333 21.975 6.95 25.025C8.91667 28.075 11.9333 31.4667 16 35.2ZM16 40.5C10.6333 35.9333 6.625 31.6917 3.975 27.775C1.325 23.8583 0 20.2333 0 16.9C0 11.9 1.60833 7.91667 4.825 4.95C8.04167 1.98333 11.7667 0.5 16 0.5C20.2333 0.5 23.9583 1.98333 27.175 4.95C30.3917 7.91667 32 11.9 32 16.9C32 20.2333 30.675 23.8583 28.025 27.775C25.375 31.6917 21.3667 35.9333 16 40.5Z" fill="#131313"/>
                  </svg>
              </span>
            <div class="w-[calc(100%-40px)] ps-3">
              <h5>{{$t('footer.headline.storefinder')}}</h5>
            </div>
          </div>
          <p class="mb-2 text-[13px] leading-4">{{$t('footer.subline.storefinder')}}</p>
          <p class="mb-2 text-sm leading-5">{{$t('footer.text.storefinder')}}</p>

          <div>
            <NuxtLink :to="formatLink('/storefinder/')" class="bg-black03 h-11 w-full max-w-[280px] font-bold border-2 border-black03 text-white flex items-center justify-center rounded-[22px] hover:bg-transparent hover:text-black03">{{$t('footer.button.storefinder')}}</NuxtLink>
          </div>
        </div>
        <div class="w-[280px] sm:w-auto">
          <div class="flex items-center mb-[10px]">
              <span class="f_icons w-10 h-10 flex items-center">
                  <svg width="40" height="33" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 32.5C2.9 32.5 1.95833 32.1083 1.175 31.325C0.391667 30.5417 0 29.6 0 28.5V4.5C0 3.4 0.391667 2.45833 1.175 1.675C1.95833 0.891667 2.9 0.5 4 0.5H36C37.1 0.5 38.0417 0.891667 38.825 1.675C39.6083 2.45833 40 3.4 40 4.5V28.5C40 29.6 39.6083 30.5417 38.825 31.325C38.0417 32.1083 37.1 32.5 36 32.5H4ZM20 18.5L4 8.5V28.5H36V8.5L20 18.5ZM20 14.5L36 4.5H4L20 14.5ZM4 8.5V4.5V28.5V8.5Z" fill="#131313"/>
                  </svg>
              </span>
            <div class="w-[calc(100%-40px)] ps-3">
              <h5>{{$t('footer.headline.newsletter')}}</h5>
            </div>
          </div>
          <p class="mb-2 text-[13px] leading-4">{{$t('footer.subline.newsletter')}}</p>
          <p class="mb-2 text-sm leading-5">{{$t('footer.text.newsletter')}}</p>

          <div>
            <form class="w-full relative flex" @submit.prevent="invokeSubmit">
            <input
                name="email"
                type="email"
                autocomplete="email"
                :class="[
                            $v.email.$error
                            ? 'border-danger'
                            : 'border-gray-300 focus:border-indigo-500',
                            ]"
                v-model="state.email"
                @blur="$v.email.$touch()"
                :placeholder="$t('footer.placeholder.newsletter')"
                class="h-11 w-full bg-cream px-5 rounded-[12px] font-figtree text-base outline-none shadow-none">

              <button type="submit" class="bg-black03 ml-2 h-11 w-14 max-w-[280px] font-bold border-2 border-black03 text-white flex items-center justify-center rounded-[12px] hover:bg-transparent hover:text-black03"><i class="fa-solid fa-paper-plane"></i></button>
            </form>

          </div>
        </div>
        <div class="w-[260px] sm:w-auto">
          <div class="flex items-center mb-[10px]">
              <span class="f_icons w-10 h-10 flex items-center">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M37 28.02L37.02 27.998M15 28.02L15.02 27.998M8 36V20C8 18.9391 8.42143 17.9217 9.17157 17.1716C9.92172 16.4214 10.9391 16 12 16H40C41.0609 16 42.0783 16.4214 42.8284 17.1716C43.5786 17.9217 44 18.9391 44 20V36C44 37.0609 43.5786 38.0783 42.8284 38.8284C42.0783 39.5786 41.0609 40 40 40H12C10.9391 40 9.92172 39.5786 9.17157 38.8284C8.42143 38.0783 8 37.0609 8 36Z" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 32H6C4.93913 32 3.92172 31.5786 3.17157 30.8284C2.42143 30.0783 2 29.0609 2 28V12C2 10.9391 2.42143 9.92172 3.17157 9.17157C3.92172 8.42143 4.93913 8 6 8H34C35.0609 8 36.0783 8.42143 36.8284 9.17157C37.5786 9.92172 38 10.9391 38 12V16M26 32C24.9391 32 23.9217 31.5786 23.1716 30.8284C22.4214 30.0783 22 29.0609 22 28C22 26.9391 22.4214 25.9217 23.1716 25.1716C23.9217 24.4214 24.9391 24 26 24C27.0609 24 28.0783 24.4214 28.8284 25.1716C29.5786 25.9217 30 26.9391 30 28C30 29.0609 29.5786 30.0783 28.8284 30.8284C28.0783 31.5786 27.0609 32 26 32Z" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </span>
            <div class="w-[calc(100%-40px)] ps-3">
              <h5>{{$t('footer.headline.points')}}</h5>
            </div>
          </div>
          <p class="mb-2 text-sm leading-5">{{$t('footer.text.points')}} </p>

          <div class="flex items-center space-x-3">
            <a  :href="$t('footer.ios.link')" target="_blank" class="block"><NuxtImg src="/assets/images/app-store.png" format="webp" loading="lazy" alt="App Store" width="140" /></a>
            <a  :href="$t('footer.android.link')" target="_blank" class="block"><NuxtImg src="/assets/images/google-play.png" format="webp" loading="lazy" alt="Google Play" width="120" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-black03 pt-5 pb-5 md:pb-4 text-white" v-if="columns && columns.value.length > 0">
    <div class="container">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 hover_f_menu gap-y-10 max-w-[80%] sm:max-w-full m-auto w-full sm:m-0">

        <div class="text-center" v-for="col in columns.value">
          <span class="f_ico block mb-4 sm:mb-2">
              <NuxtImg :src="col.iconImage.url" class="block m-auto" alt="ico 01" width="30" height="30" style="max-height:30px;" />
          </span>
          <h6 class="mb-3 sm:mb-4 font-bold text-base text-white">{{col.columnTitle}}</h6>
          <!--a v-if="col.link" :href="col.link.url" :target="col.link.target" class="flex items-center justify-center sm:justify-start gap-2 leading-3 uppercase">{{col.link.title}} <i class="fa-solid fa-angle-right"></i></a-->
        </div>

      </div>
    </div>
  </div>
</template>
